import React, { Component } from 'react';
import './Base.css';
import './App.css';
import Lottie from 'react-lottie';
import animation from './endless_orbit.json'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {isStopped: false, isPaused: false};
  }

  render() {

    const defaultOptions = {
      autoplay: true,
      animationData: animation,
    };

    return (
      <div className="App">
        <header className="p3">
          <div id="logo">
            <Lottie options={defaultOptions} />
          </div>
        </header>
        <div className="main">
          <section className="flex p2">
            <div id="text" className="sm-col sm-col-6 px2 mx-auto">
              <h3>We are a small creative partnership endevouring to build amazing things, endlessly.</h3>
              <h3>For more information or case studies of our work, please contact us at <a href="mailto:info@endlessorbit.com" className="black">info@endlessorbit.com</a></h3>
            </div>
          </section>
        </div>
        <footer className="flex p2 center">
          <div className="sm-col sm-col-6 p2 mx-auto">
            <p><a href="http://www.deepelement.com/31415926">Todd Morrison</a> &amp; <a href="http://joelunger.com">Joel Unger</a></p>
          </div>
        </footer>
      </div>
    );
  }
}

export default App;
